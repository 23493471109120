<template>
  <b-card>
    <b-overlay
      rounded="sm"
    >
      <div v-if="isError===false">
        <div>
          <p>Here you can configure additional DNS records for your domain. The changes are deployed immediately.</p>
          <p>DNS and CDN service powered by Cloudflare. </p>
        </div>
        <div
          v-if="items.length == 0"
          class="d-flex spinner-border text-primary justify-content-center"
          style="width: 2.2rem; height: 2.2rem; margin: 0 auto"
          role="status"
        />
        <div v-else>
          <b-button
            v-if="!isAddRecord"
            variant="primary"
            @click="addRecord"
          >Add record</b-button>
          <custom-dns-form
            v-if="isAddRecord"
            :custom-type="'A'"
            @hideFormC="hideFormD"
            @refreshRecordC="refreshRecord"
          />
          <!-- table -->
          <vue-good-table
            v-if="items.length > 0"
            :columns="columns"
            :rows="items"
            class="mt-2"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <div
                v-if="props.column.field == 'content'"
                style="max-width:430px;"
              >
                {{ props.row.content }}
                <div
                  v-if="typeof props.row.priority!='undefined'"
                  class="priority"
                >
                  {{ props.row.priority }}
                </div>
              </div>
              <span v-else-if="props.column.field == 'customProxyStatus'">
                <b-img
                  v-if="props.row.proxied === false"
                  :src="require('@/assets/images/svg/dns_only.svg')"
                  height="16px"
                />
                <b-img
                  v-else
                  :src="require('@/assets/images/svg/proxied.svg')"
                  height="12px"
                />
                {{ props.row.customProxyStatus }}
              </span>
              <span v-else-if="props.column.field == 'action'">
                <center v-if="props.row.editable">
                  <span class="text-success">
                    <feather-icon
                      icon="Edit3Icon"
                      size="16"
                      style="cursor: pointer;"
                      @click="showEditDnsForm(props.row)"
                    />
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span class="text-danger">
                    <feather-icon
                      icon="XCircleIcon"
                      size="16"
                      style="cursor: pointer;"
                      @click="deleteRow(props.row)"
                    />
                  </span>
                </center>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <div style="padding:20px">
            <b-form-checkbox
              v-model="redirected"
              :disabled="loading"
              @change="RedirectToDashboard"
            >
              <span v-if="!loading">Redirect root and www domain to the dashboard</span>
              <span v-else>Saving redirect option ...</span>
            </b-form-checkbox>
          </div>
        </div>
        <b-modal
          id="edit-dns"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          title="Edit Record"
          size="lg"
        >
          <custom-dns-form
            :custom-type="editRecord.type"
            :edit="true"
            :row="editRecord"
            @hideModalC="hideModal"
            @refreshRecordC="refreshRecord"
          />
        </b-modal>
      </div>
      <div v-else>
        <b-alert
          show
          variant="secondary"
        >
          <h4 class="alert-heading">
            <feather-icon
              icon="InfoIcon"
              size="18"
            /> Notice
          </h4>
          <p style="padding:30px">
            {{ errorTxt }}
          </p>
          <hr>
          <p class="mb-0">
&nbsp;
          </p>
        </b-alert>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BCard, BOverlay, BBadge, BModal, BButton, BImg, BAlert, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import customDNSForm from './custom-dns/CustomDNSForm.vue'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BCard,
    BButton,
    BImg,
    'custom-dns-form': customDNSForm,
    BModal,
    BAlert,
    BFormCheckbox,
  },
  data() {
    return {
      isError: false,
      errorTxt: '',
      isAddRecord: false,
      pageLength: 20,
      dir: false,
      loading: false,
      editRecord: {},
      columns: [
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Content',
          field: 'content',
        },
        {
          label: 'TTL',
          field: 'customTtl',
        },
        {
          label: 'Proxy Status',
          field: 'customProxyStatus',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      items: [],
      rows: [],
      redirected: false,
    }
  },
  computed: {
    customDNSData() {
      return this.$store.state.setting.customDNSData
    },
  },
  created() {
    this.fetchCustomDNS()
  },
  methods: {
    async fetchCustomDNS() {
      try {
        await this.$store.dispatch('setting/fetchCustomDNS').then(response => {
          this.redirected = response.redirect === 1
        })
        this.items = this.customDNSData
      } catch (error) {
        this.isError = true
        this.errorTxt = error
        if (typeof this.errorTxt === 'undefined') this.errorTxt = 'Connection was reset in connection to api.cloudflare.com:443'
      }
    },
    addRecord() {
      this.isAddRecord = true
    },
    hideFormD(isHideForm) {
      if (isHideForm) this.isAddRecord = false
    },
    hideModal(isHide) {
      if (isHide) this.$bvModal.hide('edit-dns')
    },
    showEditDnsForm(rec) {
      this.editRecord = rec
      this.$bvModal.show('edit-dns')
    },
    refreshRecord() {
      this.fetchCustomDNS()
    },
    deleteRow(rec) {
      this.confirmationAlert(`Are you sure you want to delete ${rec.name}?`)
        .then(async res => {
          if (res.isConfirmed) {
            const payload = {
              zone: rec.zone_id,
              recordId: rec.id,
            }
            try {
              await this.$store.dispatch('setting/deleteDNS', payload)
              this.notify({
                text: 'Successfully deleted the record.',
                variant: 'success',
              })
              this.fetchCustomDNS()
            } catch (error) {
              const errorTxt = error.response.data.error
              this.notify({
                text: errorTxt,
                variant: 'danger',
              })
            }
          }
        })
    },
    RedirectToDashboard() {
      const payload = { enable: this.redirected }
      this.loading = true
      this.$store.dispatch('setting/redirectRootWWW', payload).then(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.priority{
  float:right;
  border: 1px solid #cccccc;
  background-color: #e8e8e8;
  padding:1px 3px 1px 3px;
}
table.vgt-table th{
  font-size: 12px
}
table.vgt-table td{
  font-size: 12px
}
.modal .modal-header .close{
  margin: 2px
}
</style>
