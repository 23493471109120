<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <custom-form_a
          v-if="ctype=='A'"
          :custom_type="ctype"
          :edit="editDnsRecord"
          :record="editRecord"
          @changeTypeForm="changeTypeForm"
          @hideFormA="hideFormB"
          @hideModalA="hideModalB"
          @refreshRecordA="refreshRecordB"
        />
        <custom-form_aaaa
          v-if="ctype=='AAAA'"
          :custom_type="ctype"
          :edit="editDnsRecord"
          :record="editRecord"
          @changeTypeForm="changeTypeForm"
          @hideFormA="hideFormB"
          @hideModalA="hideModalB"
          @refreshRecordA="refreshRecordB"
        />
        <custom-form_cname
          v-if="ctype=='CNAME'"
          :custom_type="ctype"
          :edit="editDnsRecord"
          :record="editRecord"
          @changeTypeForm="changeTypeForm"
          @hideFormA="hideFormB"
          @hideModalA="hideModalB"
          @refreshRecordA="refreshRecordB"
        />
        <custom-form_txt
          v-if="ctype=='TXT'"
          :custom_type="ctype"
          :edit="editDnsRecord"
          :record="editRecord"
          @changeTypeForm="changeTypeForm"
          @hideFormA="hideFormB"
          @hideModalA="hideModalB"
          @refreshRecordA="refreshRecordB"
        />
        <custom-form_srv
          v-if="ctype=='SRV'"
          :custom_type="ctype"
          :edit="editDnsRecord"
          :record="editRecord"
          @changeTypeForm="changeTypeForm"
          @hideFormA="hideFormB"
          @hideModalA="hideModalB"
          @refreshRecordA="refreshRecordB"
        />
        <custom-form_mx
          v-if="ctype=='MX'"
          :custom_type="ctype"
          :edit="editDnsRecord"
          :record="editRecord"
          @changeTypeForm="changeTypeForm"
          @hideFormA="hideFormB"
          @hideModalA="hideModalB"
          @refreshRecordA="refreshRecordB"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import CustomForm_A from './CustomForm_A.vue'
import CustomForm_AAAA from './CustomForm_AAAA.vue'
import CustomForm_CName from './CustomForm_CName.vue'
import CustomForm_MX from './CustomForm_MX.vue'
import CustomForm_SRV from './CustomForm_SRV.vue'
import CustomForm_TXT from './CustomForm_TXT.vue'

export default {
  components: {
    BRow,
    BCol,
    'custom-form_a': CustomForm_A,
    'custom-form_aaaa': CustomForm_AAAA,
    'custom-form_cname': CustomForm_CName,
    'custom-form_mx': CustomForm_MX,
    'custom-form_srv': CustomForm_SRV,
    'custom-form_txt': CustomForm_TXT,
  },
  props: {
    customType: {
      type: String,
      default: 'A',
    },
    dns: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ctype: '',
      editDnsRecord: false,
      editRecord: {},
    }
  },
  created() {
    this.ctype = this.customType
    this.editDnsRecord = this.edit
    this.editRecord = this.row
  },
  methods: {
    changeTypeForm(typeValue) {
      this.ctype = typeValue
    },
    hideFormB(isHide) {
      this.$emit('hideFormC', isHide)
    },
    hideModalB(isHide) {
      this.$emit('hideModalC', isHide)
    },
    refreshRecordB() {
      this.$emit('refreshRecordC')
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
