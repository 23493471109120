<template>
  <div>
    <validation-observer ref="rules">
      <div v-if="edit">
        <b-row
          align-v="stretch"
          no-gutters
        >
          <b-col cols="2">
            <custom-type
              :is-disabled="ctypeDisabled"
              :custom_type_data="ctype"
              @changeTypeForm="changeTypeForm"
            />
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Service *"
              label-for="service"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="service"
                rules="required"
              >
                <b-form-input
                  id="service"
                  v-model="service"
                  placeholder="_servicename"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Protocol"
              label-for="protocol"
              style="margin-top:8px; margin-right: 1px; margin-left: 0px;"
            >
              <b-form-select
                id="protocol"
                v-model="protocol"
                :options="protocolOption"
                style="width:98%"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Name *"
              label-for="name"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  placeholder="Use @ for root"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="TTL"
              label-for="ttl"
              style="margin-top:8px"
            >
              <b-form-select
                id="ttl"
                v-model="ttl"
                :options="ttlOption"
                style="width:98%"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          align-v="stretch"
          no-gutters
        >
          <b-col cols="1">
            <b-form-group
              label="Priority *"
              label-for="priority"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="priority"
                rules="required|min_value:0|max_value:65355"
              >
                <b-form-input
                  id="priority"
                  v-model="priority"
                  placeholder="0-65355"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group
              label="Weight *"
              label-for="weight"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="weight"
                rules="required|min_value:0|max_value:65355"
              >
                <b-form-input
                  id="weight"
                  v-model="weight"
                  placeholder="0-65355"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group
              label="Port *"
              label-for="port"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="port"
                rules="required|min_value:0|max_value:65355"
              >
                <b-form-input
                  id="port"
                  v-model="port"
                  placeholder="0-65355"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-form-group
              label="Target *"
              label-for="target"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="target"
                rules="required"
              >
                <b-form-input
                  id="target"
                  v-model="target"
                  placeholder=""
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row
          align-v="stretch"
          no-gutters
        >
          <b-col
            cols="3"
            class="ml-auto"
          >
            <b-button
              style="margin:0px 2px 0px -10px"
              variant="secondary"
              @click="hideForm"
            >Cancel</b-button>
            <b-button
              style="margin:0px 0px 0px 1px"
              variant="primary"
              :disabled="sending"
              @click="saveRecord"
            >
              <span v-if="sending">
                <b-spinner
                  small
                  type="grow"
                />
                <feather-icon
                  icon="Edit3Icon"
                  size="16"
                />
              </span>
              <span v-else>Update</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row
          align-v="stretch"
          no-gutters
        >
          <b-col cols="1.8">
            <custom-type
              :custom_type_data="ctype"
              @changeTypeForm="changeTypeForm"
            />
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Service *"
              label-for="service"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="service"
                rules="required"
              >
                <b-form-input
                  id="service"
                  v-model="service"
                  placeholder="_servicename"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group
              label="Protocol"
              label-for="protocol"
              style="margin-top:8px; margin-right: 1px; margin-left: 0px;"
            >
              <b-form-select
                id="protocol"
                v-model="protocol"
                :options="protocolOption"
                style="width:98%"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Name *"
              label-for="name"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  placeholder="Use @ for root"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group
              label="TTL"
              label-for="ttl"
              style="margin-top:8px"
            >
              <b-form-select
                id="ttl"
                v-model="ttl"
                :options="ttlOption"
                style="width:98%"
              />
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group
              label="Priority *"
              label-for="priority"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="priority"
                rules="required|min_value:0|max_value:65355"
              >
                <b-form-input
                  id="priority"
                  v-model="priority"
                  placeholder="0-65355"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          align-v="stretch"
          no-gutters
        >
          <b-col cols="1">
            <b-form-group
              label="Weight *"
              label-for="weight"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="weight"
                rules="required|min_value:0|max_value:65355"
              >
                <b-form-input
                  id="weight"
                  v-model="weight"
                  placeholder="0-65355"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group
              label="Port *"
              label-for="port"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="port"
                rules="required|min_value:0|max_value:65355"
              >
                <b-form-input
                  id="port"
                  v-model="port"
                  placeholder="0-65355"
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="7">
            <b-form-group
              label="Target *"
              label-for="target"
              style="margin-top:8px"
            >
              <validation-provider
                #default="{ errors }"
                name="target"
                rules="required"
              >
                <b-form-input
                  id="target"
                  v-model="target"
                  placeholder=""
                  style="width:98%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button
              style="margin:29px 2px 0px -10px"
              variant="secondary"
              @click="hideForm"
            >Cancel</b-button>
            <b-button
              style="margin:29px 0px 0px 1px;"
              variant="primary"
              :disabled="sending"
              @click="saveRecord"
            >
              <span v-if="sending">
                <b-spinner
                  small
                  type="grow"
                />
                <feather-icon
                  icon="SaveIcon"
                  size="16"
                />
              </span>
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import axios from '@/libs/axios'
import CustomType from './CustomType.vue'

export default {
  components: {
    BFormInput,
    BFormSelect,
    BButton,
    BFormGroup,
    CustomType,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    custom_type: {
      type: String,
      default: 'A',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sending: false,
      ctypeDisabled: false,
      ctype: '',
      service: '',
      protocol: '_tcp',
      protocolOption: [
        { value: '_tcp', text: 'TCP' },
        { value: '_udp', text: 'UDP' },
        { value: '_tls', text: 'TLS' },
      ],
      name: '',
      ttl: 1,
      ttlOption: [
        { value: 1, text: 'Auto' },
        { value: 120, text: '2 min' },
        { value: 300, text: '5 min' },
        { value: 600, text: '10 min' },
        { value: 900, text: '15 min' },
        { value: 1800, text: '30 min' },
        { value: 3600, text: '1 hr' },
        { value: 7200, text: '2 hr' },
        { value: 18000, text: '5 hr' },
        { value: 43200, text: '12 hr' },
        { value: 86400, text: '1 day' },
      ],
      priority: '',
      weight: '',
      port: '',
      target: '',
    }
  },
  created() {
    this.ctype = this.custom_type
  },
  mounted() {
    if (this.edit === true) {
      this.ctypeDisabled = true
      this.service = this.record.service
      this.protocol = this.record.protocol
      this.name = this.record.name
      this.ttl = this.record.ttl
      this.priority = this.record.priority
      this.weight = this.record.weight
      this.port = this.record.port
      this.content = this.record.content
    }
  },
  methods: {
    changeTypeForm(typedata) {
      this.$emit('changeTypeForm', typedata)
    },
    hideForm() {
      if (this.edit === true) {
        this.$emit('hideModalA', true)
      } else {
        this.$emit('hideFormA', true)
      }
    },
    async saveRecord() {
      if (this.name.includes('trafficmanager') || this.name.includes('traficmanager')){ // eslint-disable-line
        this.$bvToast.toast("Unable to make changes to the 'trafficmanager' records. These records are managed by us and are needed to keep your network working properly.", {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      const isPassed = await this.$refs.rules.validate().then(success => success)
      if (isPassed) {
        this.sending = true
        const payload = {
          type: this.ctype,
          servicename: this.service,
          protocol: this.protocol,
          name: this.name,
          ttl: this.ttl,
          priority: this.priority,
          weight: this.weight,
          port: this.port,
          content: this.target,
        }

        let msg = 'Successfully added the record.'
        let uri = 'api/settings/user/save_dns'

        if (this.edit === true) {
          payload.zone = this.record.zone_id
          payload.recordId = this.record.id
          msg = 'Successfully edited the record.'
          uri = 'api/settings/user/update_dns'
        }
        await axios.post(uri, payload)
          .then(response => {
            if (response.status === 200) {
              this.$bvToast.toast(msg, {
                title: 'Success',
                variant: 'success',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 5000,
              })
            } else {
              this.$bvToast.toast(response.data.error, {
                title: 'Error',
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 15000,
              })
            }
          })
        this.$emit('refreshRecordA')
        this.sending = false
        if (this.edit === true) this.$emit('hideModalA', true)
        // clear input
        this.service = ''
        this.protocol = '_tcp'
        this.name = ''
        this.ttl = 1
        this.priority = ''
        this.weight = ''
        this.port = ''
        this.target = ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
