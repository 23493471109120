var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"rules"},[(_vm.edit)?_c('div',[_c('b-row',{attrs:{"align-v":"stretch","no-gutters":""}},[_c('b-col',{attrs:{"cols":"2"}},[_c('custom-type',{attrs:{"is-disabled":_vm.ctypeDisabled,"custom_type_data":_vm.ctype},on:{"changeTypeForm":_vm.changeTypeForm}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Service *","label-for":"service"}},[_c('validation-provider',{attrs:{"name":"service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"service","placeholder":"_servicename"},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4176435883)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px","margin-right":"1px","margin-left":"0px"},attrs:{"label":"Protocol","label-for":"protocol"}},[_c('b-form-select',{staticStyle:{"width":"98%"},attrs:{"id":"protocol","options":_vm.protocolOption},model:{value:(_vm.protocol),callback:function ($$v) {_vm.protocol=$$v},expression:"protocol"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Name *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"name","placeholder":"Use @ for root"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3140326800)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"TTL","label-for":"ttl"}},[_c('b-form-select',{staticStyle:{"width":"98%"},attrs:{"id":"ttl","options":_vm.ttlOption},model:{value:(_vm.ttl),callback:function ($$v) {_vm.ttl=$$v},expression:"ttl"}})],1)],1)],1),_c('b-row',{attrs:{"align-v":"stretch","no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Priority *","label-for":"priority"}},[_c('validation-provider',{attrs:{"name":"priority","rules":"required|min_value:0|max_value:65355"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"priority","placeholder":"0-65355"},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2552281891)})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Weight *","label-for":"weight"}},[_c('validation-provider',{attrs:{"name":"weight","rules":"required|min_value:0|max_value:65355"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"weight","placeholder":"0-65355"},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2645519907)})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Port *","label-for":"port"}},[_c('validation-provider',{attrs:{"name":"port","rules":"required|min_value:0|max_value:65355"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"port","placeholder":"0-65355"},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3161788483)})],1)],1),_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Target *","label-for":"target"}},[_c('validation-provider',{attrs:{"name":"target","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"target","placeholder":""},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4002158062)})],1)],1)],1),_c('hr'),_c('b-row',{attrs:{"align-v":"stretch","no-gutters":""}},[_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"3"}},[_c('b-button',{staticStyle:{"margin":"0px 2px 0px -10px"},attrs:{"variant":"secondary"},on:{"click":_vm.hideForm}},[_vm._v("Cancel")]),_c('b-button',{staticStyle:{"margin":"0px 0px 0px 1px"},attrs:{"variant":"primary","disabled":_vm.sending},on:{"click":_vm.saveRecord}},[(_vm.sending)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_c('feather-icon',{attrs:{"icon":"Edit3Icon","size":"16"}})],1):_c('span',[_vm._v("Update")])])],1)],1)],1):_c('div',[_c('b-row',{attrs:{"align-v":"stretch","no-gutters":""}},[_c('b-col',{attrs:{"cols":"1.8"}},[_c('custom-type',{attrs:{"custom_type_data":_vm.ctype},on:{"changeTypeForm":_vm.changeTypeForm}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Service *","label-for":"service"}},[_c('validation-provider',{attrs:{"name":"service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"service","placeholder":"_servicename"},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px","margin-right":"1px","margin-left":"0px"},attrs:{"label":"Protocol","label-for":"protocol"}},[_c('b-form-select',{staticStyle:{"width":"98%"},attrs:{"id":"protocol","options":_vm.protocolOption},model:{value:(_vm.protocol),callback:function ($$v) {_vm.protocol=$$v},expression:"protocol"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Name *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"name","placeholder":"Use @ for root"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"TTL","label-for":"ttl"}},[_c('b-form-select',{staticStyle:{"width":"98%"},attrs:{"id":"ttl","options":_vm.ttlOption},model:{value:(_vm.ttl),callback:function ($$v) {_vm.ttl=$$v},expression:"ttl"}})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Priority *","label-for":"priority"}},[_c('validation-provider',{attrs:{"name":"priority","rules":"required|min_value:0|max_value:65355"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"priority","placeholder":"0-65355"},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"align-v":"stretch","no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Weight *","label-for":"weight"}},[_c('validation-provider',{attrs:{"name":"weight","rules":"required|min_value:0|max_value:65355"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"weight","placeholder":"0-65355"},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Port *","label-for":"port"}},[_c('validation-provider',{attrs:{"name":"port","rules":"required|min_value:0|max_value:65355"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"port","placeholder":"0-65355"},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"7"}},[_c('b-form-group',{staticStyle:{"margin-top":"8px"},attrs:{"label":"Target *","label-for":"target"}},[_c('validation-provider',{attrs:{"name":"target","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"98%"},attrs:{"id":"target","placeholder":""},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticStyle:{"margin":"29px 2px 0px -10px"},attrs:{"variant":"secondary"},on:{"click":_vm.hideForm}},[_vm._v("Cancel")]),_c('b-button',{staticStyle:{"margin":"29px 0px 0px 1px"},attrs:{"variant":"primary","disabled":_vm.sending},on:{"click":_vm.saveRecord}},[(_vm.sending)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"16"}})],1):_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }