<template>
  <div>
    <validation-observer ref="rules">
      <b-row
        align-v="stretch"
        no-gutters
      >
        <b-col cols="2">
          <custom-type
            :is-disabled="ctypeDisabled"
            :custom_type_data="ctype"
            @changeTypeForm="changeTypeForm"
          />
        </b-col>
        <b-col cols="5">
          <b-form-group
            label="Name *"
            label-for="name"
            style="margin-top:8px"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Use @ for root"
                style="width:98%"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group
            label="TTL"
            label-for="ttl"
            style="margin-top:8px"
          >
            <b-form-select
              id="ttl"
              v-model="ttl"
              :options="ttlOption"
              style="width:120px"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Content *"
            label-for="content"
            style="margin-top:8px"
          >
            <validation-provider
              #default="{ errors }"
              name="content"
              rules="required"
            >
              <b-form-textarea
                id="content"
                v-model="content"
                placeholder="Enter something..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <hr v-if="edit">
      <b-row v-if="edit">
        <b-col
          cols="3.9"
          class="ml-auto"
        >
          <b-button
            style="margin:0px 2px 0px -10px"
            variant="secondary"
            @click="hideForm"
          >Cancel</b-button>
          <b-button
            style="margin:0px 0px 0px 1px"
            variant="primary"
            :disabled="sending"
            @click="saveRecord"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              <feather-icon
                icon="Edit3Icon"
                size="16"
              />
            </span>
            <span v-else>Update</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <b-button
            style="margin:1px 2px 0px 1px"
            variant="secondary"
            @click="hideForm"
          >Cancel</b-button>
          <b-button
            style="margin:1px 0px 0px 1px;"
            variant="primary"
            :disabled="sending"
            @click="saveRecord"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              <feather-icon
                icon="SaveIcon"
                size="16"
              />
            </span>
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>

    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BButton, BFormTextarea, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import axios from '@/libs/axios'
import CustomType from './CustomType.vue'

export default {
  components: {
    BFormInput,
    BFormSelect,
    BButton,
    BFormGroup,
    CustomType,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormTextarea,
    BSpinner,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    custom_type: {
      type: String,
      default: 'A',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sending: false,
      ctypeDisabled: false,
      ctype: '',
      name: '',
      content: '',
      ttl: 1,
      ttlOption: [
        { value: 1, text: 'Auto' },
        { value: 120, text: '2 min' },
        { value: 300, text: '5 min' },
        { value: 600, text: '10 min' },
        { value: 900, text: '15 min' },
        { value: 1800, text: '30 min' },
        { value: 3600, text: '1 hr' },
        { value: 7200, text: '2 hr' },
        { value: 18000, text: '5 hr' },
        { value: 43200, text: '12 hr' },
        { value: 86400, text: '1 day' },
      ],

    }
  },
  created() {
    this.ctype = this.custom_type
  },
  mounted() {
    if (this.edit === true) {
      this.ctypeDisabled = true
      this.name = this.record.name
      this.content = this.record.content
      this.ttl = this.record.ttl
    }
  },
  methods: {
    changeTypeForm(typedata) {
      this.$emit('changeTypeForm', typedata)
    },
    hideForm() {
      if (this.edit === true) {
        this.$emit('hideModalA', true)
      } else {
        this.$emit('hideFormA', true)
      }
    },
    async saveRecord() {
      if (this.name.includes('trafficmanager') || this.name.includes('traficmanager')){ // eslint-disable-line
        this.$bvToast.toast("Unable to make changes to the 'trafficmanager' records. These records are managed by us and are needed to keep your network working properly.", {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      const isPassed = await this.$refs.rules.validate().then(success => success)
      if (isPassed) {
        this.sending = true
        const payload = {
          type: this.ctype,
          name: this.name,
          content: this.content,
          ttl: this.ttl,
        }

        // if done edit..
        let msg = 'Successfully added the record.'
        let uri = 'api/settings/user/save_dns'

        if (this.edit === true) {
          payload.zone = this.record.zone_id
          payload.recordId = this.record.id
          msg = 'Successfully edited the record.'
          uri = 'api/settings/user/update_dns'
        }
        await axios.post(uri, payload)
          .then(response => {
            if (response.status === 200) {
              this.$bvToast.toast(msg, {
                title: 'Success',
                variant: 'success',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 5000,
              })
            } else {
              this.$bvToast.toast(response.data.error, {
                title: 'Error',
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 15000,
              })
            }
          })
        this.$emit('refreshRecordA')
        this.sending = false
        if (this.edit === true) this.$emit('hideModalA', true)
        this.name = ''
        this.content = ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
