<template>
  <b-form-group
    label="Type"
    label-for="type"
    style="margin-top:8px"
  >
    <b-form-select
      id="type"
      v-model="customType"
      :disabled="isDisabled"
      :options="customTypeOptions"
      style="width:98%;"
      @change="getType"
    />
  </b-form-group>
</template>

<script>
import {
  BFormSelect, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormSelect,
    BFormGroup,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    custom_type_data: {
      type: String,
      default: 'A',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customType: '',
      customTypeOptions: ['A', 'AAAA', 'CNAME', 'TXT', 'SRV', 'MX'],
    }
  },
  created() {
    this.customType = this.custom_type_data
  },
  methods: {
    getType() {
      this.$emit('changeTypeForm', this.customType)
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
